import { MDBBtn } from 'mdbreact';
import { Col, Row } from 'reactstrap';
import * as BsIcons from 'react-icons/bs';
import GetYourPostBase from './GetYourPostBase';
import Const from '../../components/Const';
import Consierge from '../../images/consierge-greet-avatar.png';
import Send from '../../images/send-white.png';
import { getInitAItextAreaHeight, getAIsendArrowHeight, setAItextAreaHeight,
         setAItextAreaOpacity, getAItextAreaHeight, setAIsendArrowOpacity } from '../../utils/AIUtils.js';
import { addOrientationChangeListener, removeOrientationChangeListener } from '../../utils/SessionUtils.js';

export default class GetYourPostMobile extends GetYourPostBase {
  constructor(props) {
    super(props);

    this.DEFAULT_HEIGHT = 80; // 5rem 
    this.MIN_HEIGHT = 24; 
    this.MAX_TIMEOUT = 2500;
    this.TIME_INTERVAL = 100;
    this.AItextareaHeightNoKeyBoard = getInitAItextAreaHeight();
    this.AItextareaHeightWithKeyBoard = 0;
    this.AIsendArrowHeight = getAIsendArrowHeight();
    this.state.aiInputClassName = "";
    this.state.isKeyboardUp = false;
    this.state.hide = false;
    this.bigTextAreaIsMeasured = false;
    this.fullVisualViewportHeight = 0;
    this.imageUploadBlocker = false;
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
  }

  componentDidMount() {
    this.toggle();
  }

  componentDidUpdate() {
		// This is needed because of transition from AI input screen with keyboard to main AI screen without it.
		// When  disableAIinput() is called in the begining of AI request we can still be in AI input screen, where 
		// not all main AI screen elements are available, so here we disable them when we are in main AI screen.
		if (!this.state.submitToAINotSentYet) {
			this.disableAIinput(true, false);
		}
	}

  observeOrientationChange(toggleModal) {
		if (toggleModal) {
			// pop-up is window is about to pop up
			addOrientationChangeListener(this);
		}
	}

	orientationChangeProcessor() {
		this.toggle();
		this.state.parent.setState({ orientationChanged : true }); // nav will change the '+' button content
		removeOrientationChangeListener();
	}

  setStateAttributes() {
    if (this.state.modal) {
      // Post modal pop-up is closing
      if (this.timeoutID !== -1) {
        clearTimeout(this.timeoutID);
      }
      this.timeoutID = setTimeout(this.activateAddPostButton, Const.MOBIL_BUTTON_ACTIVATION_DELAY, this);
    }
  }

  activateAddPostButton(that) {
    let parent = that.state.parent;

    if (parent.mounted) {
      // parent is Nav, if parent.mounted is false, the button will be reactivated in its constructed
      if (parent && parent.hasOwnProperty('state') && 
          parent.state.hasOwnProperty('showMobilButton') && !parent.state.showMobilButton) {
          parent.setState({ showMobilButton : true, showModalPopUp : false });
      }
    }
    clearTimeout(this.timeoutID);
  }

  onFocusHandler = () => {
    let stateObj = {
      isKeyboardUp : true, 
      AIpostUserNameHeader : '',  
      postUserNameHeader : '', 
      backgroundImageClass : this.BACKGROUND_IMAGE_CLASS_NO_IMAGE
    };

    if (!this.bigTextAreaIsMeasured) {
      stateObj.hide = false;
      setAIsendArrowOpacity(0.01);
      setAItextAreaOpacity(0.01);
      this.setState(stateObj);
      this.processAiMobilInputHeight();
    }
    else {
      setAItextAreaHeight(this.AItextareaHeightWithKeyBoard);
      this.setState(stateObj);
    }
  }

  onBlurHandler = () =>  {  
    setTimeout(() => {
      setAItextAreaHeight(this.AItextareaHeightNoKeyBoard);
      this.setState(
                    { 
                      isKeyboardUp : false, 
                      AIpostUserNameHeader : this.AI_POST_USER_NAME_HEADER, 
                      postUserNameHeader  : this.POST_USER_NAME_HEADER, 
                      // button2 can be active when we are in the 2nd AI screen and go to create my post
                      backgroundImageClass : (this.state.activeButton === 'button1') ? this.BACKGROUND_IMAGE_CLASS
                                                                                     : this.BACKGROUND_IMAGE_CLASS_NO_IMAGE
                    });
    }, this.TIMEOUT);
  }

  processAiMobilInputHeight() {
    let intervalInvocation = false;
    let visualViewportHeight = window.visualViewport.height;
    let interval = setInterval(() => {
                      if (window.visualViewport.height < visualViewportHeight) {
                        visualViewportHeight = window.visualViewport.height;
                        intervalInvocation = true;
                        this.setAiMobilInputHeight(true);
                      }
                   }, this.TIME_INTERVAL);

    setTimeout(() => {
      clearInterval(interval);
      if (!intervalInvocation) {
        this.setAiMobilInputHeight(false);
      }
    }, this.MAX_TIMEOUT);
  }

  setAiMobilInputHeight(isCalculateHeight) {
    let aiBody = document.getElementById('aiBody');
    let rect;
    let aiInputHeight = getAItextAreaHeight();
    let savePadding = 4;

    if (isCalculateHeight) {
      rect = aiBody.getBoundingClientRect();
      aiInputHeight = window.visualViewport.height - (rect.bottom - aiInputHeight) - savePadding;
      if (aiInputHeight <= this.MIN_HEIGHT) {
        aiInputHeight = this.DEFAULT_HEIGHT;
      }
    }
    else {
      aiInputHeight = this.DEFAULT_HEIGHT;
    }
    setAItextAreaHeight(aiInputHeight);
    setAItextAreaOpacity(1);
    setAIsendArrowOpacity(1);
    this.AItextareaHeightWithKeyBoard = aiInputHeight;
    this.bigTextAreaIsMeasured = true;
  }

  getHeader() {
    return "Create Post";
  }
  
  getButton() {
		return (
		  (this.state.noPostText && this.state.imageIsNotUploaded) || this.textOverflow
		  ? <MDBBtn id="dialog-button" className="btn btn-primary pointer-noview"  disabled = { true } >Post</MDBBtn>
		  : <MDBBtn id="dialog-button" className="btn btn-primary" type="submit" value="Submit">Post</MDBBtn>
		)
	}

  getUploadImageIckon() {
    return (
      <div className="previewComponent">
          <label id="label" htmlFor="oneimage" className="pointer-view">
            <div className="media-icon"> 
              <BsIcons.BsImageAlt className="icon" /> 
            </div>
          </label>
          <input className="fileInput hidden" id="oneimage" disabled = {this.state.imgIckonDisabled}
            type="file" 
            accept={Const.ACCEPTED_IMAGE_TYPES}
            onChange={ this.handleImageChange }
            onClick={ this.handleUploadImageClick }
          />
      </div>
    )
  }

  handleUploadImageClick = (e) => {
    if (this.imageUploadBlocker) {
      e.preventDefault();
    }
  }

  getToAIIckon() {
    return (
      <div className="previewComponent">
          <label id="labelAI" htmlFor="oneimage" className="pointer-view">
            <div className="avatar-ai-sm" 
                data-tip="Ask AI Concierge" 
                data-event-off={'mouseout'} data-event='mouseover' data-delay-show={Const.DELAY} 
                data-for='toolTip6AI' data-class="mytooltip" data-place='right'
                onClick={ () => this.handleGetToAIButtonClick()} 
                >
                <img src={Consierge} className="" alt="Avatar" />
            </div>
          </label>
      </div>
    )
  }

  handleGetToAIButtonClick() {
    let button1StateObj = { 
          activeButton : 'button1',
          borderAI : this.BOLD_COLORED_BORDER, 
          fontWeightAI : this.BOLD_FONT, 
          borderUser : this.BOLD_BORDER,
          fontWeightUser : this.LIGHT_FONT, 
          backgroundImageClass : this.BACKGROUND_IMAGE_CLASS
      };

      this.imageUploadBlocker = true; 
      // getting to AI concierge from Create My Post (at this time keyboard is almost always up, so TIMEOUT is used)
      setTimeout(() => {
        this.setState( button1StateObj );
        this.imageUploadBlocker = false;
      }, this.TIMEOUT);
  }

  // AI concierge

  getProfileInfo(disabledAI) {
    if (!this.state.isKeyboardUp)  {
      return this.getProfileInfoDiv(disabledAI);
    }
  }

  getRadioButtons(disabledAI) {
    if (!this.state.isKeyboardUp) {
      return this.getRadioButtonsColumn(disabledAI);
    }
  }

  getAIinput() {
    let loginUser = this.state.grandParent.state.loginUser;
		let aiLimitCond;

    if ((this.state.AIrequestOption === this.AI_IMAGE_CREATE) || (this.state.AIrequestOption === this.AI_POST_ILLUSTRATE) || (this.state.AIrequestOption === this.AI_POST_VIDEO_ILLUSTRATE)) {
			aiLimitCond = this.aiImageLimitCondition(loginUser);
		}
		else {
			aiLimitCond = this.aiPostLimitCondition(loginUser);
		}
		return (
			<div id="aiInputContainer" className="apr-0">
            <textarea 
                autoFocus = { false }
                name = "aiRequestTool"
                autoCorrect = 'ON'
                id = "aiInput" 
                maxLength = {this.AI_INPUT_MAX_LEN}
                placeholder = { this.state.AIrequestPlaceHolder }
                value = { this.state.messageForAI } 
                onChange = {this.handleAIinputChange} 
                onFocus={ this.onFocusHandler }
                onBlur={ () => this.onBlurHandler() }
                disabled = { 
                              !aiLimitCond ||
                              !this.state.submitToAINotSentYet 
                            }
                className = "x-input ai-textarea-mobile-n">                 
            </textarea>
      </div>
		)
	}

  getAIarrowButton(messageForAI, submitToAINotSentYet) {
    if (this.state.isKeyboardUp) {
      if (this.state.hide) {
        // keep hiden till keyboard is completely up
        return null;
      }
      else {
        return (
          messageForAI && submitToAINotSentYet 
          ? <Col lg={1} xs={12} className="p-0 btn-look" onClick={ this.handleSubmitToAI }>
              <div id='sendArrow' className="send" >
                <img src={ Send } className="pointer-view" alt="Avatar" />
              </div>
            </Col>
          : <Col lg={1} xs={12} className="p-0 btn-look">
              <div id='sendArrow' className="send">
                <img src={ Send } style = { this.LIGHT_FONT } alt="Avatar" />	
              </div>
            </Col>
        );
      }
    }
    else {
      return null;
    }
  }

  getNumberOfAIRequestsLeftPhone(numberOfAIRequestsLeft, remainingRequestText) {
    let adjustedRemainingRequestText ;
	
    if (!this.state.isKeyboardUp) {
      adjustedRemainingRequestText = this.adjustMarketPlaceCustomerText(numberOfAIRequestsLeft, remainingRequestText);
      return (
        <Row>
          <p id="remainingAIrequests" className="try">{ numberOfAIRequestsLeft + adjustedRemainingRequestText }</p> 	
        </Row>
      )
    }
    else {
      return null;
    }
  }

  noVirtualKeybardAfterDalleImage() {
    this.setState({ autoFocus : false });
  }
}