import React, { PureComponent } from 'react';
import { getShareDropDownMobil, getShareDropDownDeskTop } from '../utils/RenderPages.js';
import Envir from '../components/Envir';
import Const from '../components/Const';
import { getDevice, invokeService } from '../components/Utils.js';
import { modalPopUpScrollProcessor } from '../components/ScrollUtils.js';
import { copyToClipBoard, invokeServer } from '../utils/SharePostUtils';
import  { isMobilPlatform } from '../utils/AppUtils.js';

class ShareDropdown extends PureComponent {
   constructor(props) {
      super();

      const { postSharingParms } = props;
      this.state = {
        // dropdown
        clicked: false,
		  show : false,
        modal: false,
        sharePost : postSharingParms.sharePost,
        loginUser : postSharingParms.loginUser,
        // radio
        collection : "" , // use this one if you don't want any default value for collection
        // service
        serviceErrorLevel : Const.NO_ERROR_LEVEL
      };
      this.ref = React.createRef();
      // dropdown
      this.handleClick = this.handleClick.bind(this);
      this.handleClickToCopyLink = this.handleClickToCopyLink.bind(this);
      this.handleDeskTopClick = this.handleDeskTopClick.bind(this);
      this.handleJustClosingClick = this.handleJustClosingClick.bind(this);
      this.hostName = window.location.hostname;
      this.cracker = '212.1.214.138';
      this.port = (this.hostName === 'localhost')  ? ':3000' : '';
      this.scheme = ((this.hostName === 'localhost') || (this.hostName === this.cracker)) ? 'http://' : 'https://';
      this.url = this.scheme + this.hostName + this.port + '/Post/';
      this.localServeUrl = Const.VPS_SERVER_URL_SAVE_POST_INFO;
   }

   toggle = () => {
      let loginUser = this.state.loginUser;

      if (loginUser && !loginUser.testDrive) {
         // if this.state.modal = false - we will desable page, otherwise - enable it
         modalPopUpScrollProcessor(this);
		   this.setState({ modal : !this.state.modal });
      }      
	}

	setShow = (value) => {
      this.setState({ show : value });
   }

   async handleClick() {
      // this is social media button click
      this.setState({clicked: !this.state.clicked});
      this.toggle();
      this.storePostMetaDataLocaly();
      this.storeUserShareInDB();
      this.setState({clicked: !this.state.clicked});
   }

   handleDeskTopClick() {
      let loginUser = this.state.loginUser;

      if (loginUser && !loginUser.testDrive) {
         // this click does not store anything in DB
         this.setState({clicked: !this.state.clicked});
         this.toggle();
      }
   }

   handleJustClosingClick(event) {
      if ((event.target.id === 'sBox') || (event.target.id === 'sharedModal') || (event.target.id === 'main')) {
         // Not a click not on a social media button : closing social modal window
         this.setState({clicked: !this.state.clicked});
         this.toggle();
      }
   }

   async handleClickToCopyLink(event) {
      event.preventDefault();
      this.setState({clicked : false, show : true});
      this.toggle();
      await copyToClipBoard(this.url + this.state.sharePost.postId)
      this.storePostMetaDataLocaly();
      this.storeUserShareInDB();
   }

   isMobilPlatform() {
      let device = getDevice();
      let androidPlatform = ((device === Const.TABLET) || (device === Const.ANDROID)) ? true : false;
      let iosPlatform = ((device === Const.IPAD) || (device === Const.IPHONE)) ? true : false;

      if (Envir.ANDROID_APP || Envir.iOS_APP || androidPlatform || iosPlatform) {
         return true;; 
      }
      else {
         return false;
      }
   }

   async storeUserShareInDB() {
      let sharePost = this.state.sharePost;
      let data;
   
      if (sharePost) {
         data = {
            mode : Const.STORE_USER_SHARE_MODE,
            post_id : sharePost.postId,
         };
         await invokeService(Const.OCA_SERVICES_URL, data, this);
         if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
            sharePost.sharesCount++;
            this.setState({sharePost : sharePost});
         }
      }
   }

   async storePostMetaDataLocaly() {
      let sharePost = this.state.sharePost;
      let data;
      let imgUrl;
      let post;
   
      if (sharePost && Envir.EXPRESS_SERVER) {
         imgUrl = sharePost.hasOwnProperty('postImg') ? sharePost.postImg : '';
         post = sharePost.hasOwnProperty('post') ? sharePost.post : '';
         data = {
            postId : sharePost.postId,
            userName : sharePost.userName,
            imgUrl : imgUrl, 
            post : post,
            imgHeight : sharePost.imgHeight,
            imgWidth : sharePost.imgWidth
         };
         if (sharePost.hasOwnProperty('postVideo')) {
            data.videoUrl = sharePost.postVideo;
         }
         await invokeServer(this.localServeUrl, data, this); 
      }
   }

   render() {
      const { sharePost, show, modal} = this.state; // this is the clicked post we receive from Home
      const sharedPostUrl = this.url + sharePost.postId;
	  
      if (isMobilPlatform()) {
         return getShareDropDownMobil(modal, sharePost, sharedPostUrl, show, this);
      }
      else {
         return getShareDropDownDeskTop(sharePost, show, this);
      }
   }
}

export default ShareDropdown;
