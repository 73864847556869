import React from "react";
import { Redirect } from 'react-router-dom';
import '../App.css';
import {Button, Form, FormGroup, Input, Col} from 'reactstrap';
import { MDBBtn } from 'mdbreact';
import Envir from '../components/Envir';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from "react-icons/bs";
import Const from '../components/Const';
import { invokeService, isLoginUserName, validateUserName, validateEmail, validatePasswordStrength, 
	     setLocalCookieForOcaUser, sleepingCat, messageModalWindow, getLocalCookieKeyName, 
		 getLocalTokenCookieKeyName, getAppCookie, contactUs, setLocalCookieForOcaMkt } from './Utils.js';
import { getLoginUserJson } from '../components/getData/GetLoginUserJson';
import { getWelcomeGreting, getPolicy  } from './Policy.js';
import  { handleTestDriveClick } from '../utils/RenderPages.js';
import { isDesktop } from '../utils/AppUtils.js';
import  { setOverflow, setWrapperHeight, onFocusHandler, onBlurHandler } from '../utils/LoginUtils.js';
import  { postsAndFollowersClassNames } from '../utils//PostUtils.js';

const initialState = {
	loginUser : null,
	userAccountRetrieval : true,
	loginName: "",
	password: "",
	loginNameError: "",
	passwordError: "",
	checked : false, // check box
	hide : true, //password show hide
	personalOrCompany : null,
	modal: false,
	messageModal: false,
    modalAgreement: false,
	testDrive: false,
	wrapperHeight: 0,
	styleObj : {},
	//
	serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
	serviceStatusCode : Const.INIT_VALUE,
	serviceErrorLevel : Const.NO_ERROR_LEVEL,
	serviceInvocationError : false,
	flag : Const.INIT_VALUE
};

export default class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.handlePesonalOrCompanyClick = this.handlePesonalOrCompanyClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.toggleAgreement = this.toggleAgreement.bind(this);
		this.wrapperRef = React.createRef();
		this.marketPlacefulfilmentProcessor();
	}

	async componentDidMount() {
		let cookieKey = getLocalCookieKeyName();
		let userName = await getAppCookie(cookieKey);
		let token;
		
		setOverflow(this);
		setWrapperHeight(this);
		if (userName) {
			getLoginUserJson(this, userName);
		}
		else {
			cookieKey = getLocalTokenCookieKeyName();
			token = await getAppCookie(cookieKey);
			// if we came here from StartLogin: token is ALWAYS present, so condition (!token) is false
			if (!token) {
				window.location = '/';
			}
		}
	}

	//password show hide
	hideSwitch = ev => {
		this.setState({ hide: !this.state.hide })
	}

	handleChange = event => {
		this.setState({
			[event.target.name] : event.target.value.trim(),
			loginNameError : "",
			passwordError : ""
		});
	};

	handleClick = event => {
		event.preventDefault();
	};

	handlePesonalOrCompanyClick(event) {
		let pOrC;

		event.preventDefault();
		if(event.target.id === 'p') {
			pOrC = 'p';
		}
		else {
			pOrC = 'c';
		}
		this.setState({ personalOrCompany : pOrC, modal: !this.state.modal });
	}

	validate() {
		let loginNameError = "";
		let passwordError = "";
		let isLoginUserNameValid;
		let passwordStrength = validatePasswordStrength(this.state.password);
		let ret = true;

		if (isLoginUserName(this.state.loginName)) {
			isLoginUserNameValid = validateUserName(this.state.loginName);
			if (!isLoginUserNameValid) {
			loginNameError = Const.VALID_USER_NAME_REQUIRED;
			ret = false;
			}
		}
		else {
			isLoginUserNameValid = validateEmail(this.state.loginName);
			if (!isLoginUserNameValid) {
			loginNameError = Const.VALID_EMAIL_REQUIRED;
			ret = false;
			}
		}
		if (passwordStrength < Const.GOOD){
			passwordError = Const.VALID_PASSWORD_REQUIRED;
			ret = false;
		}
		this.setState({ loginNameError, passwordError });
		return ret;
	};

	handleSubmit = async event => {
		let isValid;
		let response;

		event.preventDefault();
		isValid = this.validate();
		if (isValid) {
			response = await this.validateUserInDB();
			if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
				setLocalCookieForOcaUser(response.result.name, response.result.edt);
				this.setState({ userAccountRetrieval : false });
				getLoginUserJson(this, response.result.name);
			}
			if (this.state.flag === Const.TOO_MANY_TRIES) {
				this.setState({ 
					loginNameError : Const.TOO_MANY_TRIES_NEED_CLOSE_BROWSER_MESSAGE,
					serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
				});
			} 
			else if (this.state.serviceErrorLevel === Const.USER_ERROR_LEVEL) {
				this.setState({
					loginNameError : Const.INVALID_CREDENTIALS,
					serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET
				});
			}
		}
	};

	async validateUserInDB() {
		let  data = {
				// Login service
				"login_name": this.state.loginName,
				"password": this.state.password,
				"keep" : Const.KEEP_ME_SIGNED_IN,
				"mode" : Const.LOGIN_MODE
			};
		let response = await invokeService(Const.LOGIN_URL, data, this);

		return response;
	}

	toggle = () => {
		this.setState({
		  modal: !this.state.modal
		});
	}

    toggleAgreement = () => {
		this.setState({
			modalAgreement: !this.state.modalAgreement
		});
	}

	marketPlacefulfilmentProcessor() {
		let params = new URLSearchParams(window.location.search);
		let customerId = params.get('customerId');
		let isPricingDimension;
		let obj;
		
		if (customerId) {
			isPricingDimension = params.get('dalleSubscription');
			obj = {
				customer_id : customerId,
				pricing_dimension : isPricingDimension ? isPricingDimension : '0'
			}
			setLocalCookieForOcaMkt(obj);
		}
	}

	render() {
		const { serviceInvocationError, serviceErrorLevel, serviceInvocationStatus, serviceStatusCode, hide,  
			    loginUser, isUserComplete, personalOrCompany, testDrive, styleObj } = this.state;
		const focus = isDesktop() ? true : false;
		let pfClassNames = postsAndFollowersClassNames('');
		let loginBackgroundClass = pfClassNames.loginBackground;

		if (this.state.personalOrCompany) {
			return  (<Redirect to={{ pathname: "/signup", params: { personalOrCompany } }} />)	
		}
	    else if (serviceErrorLevel === Const.USER_ERROR_CRITICAL_LEVEL) {
			return (
			  messageModalWindow(this, this.state.header, this.state.text)
			)
		}
		else if (serviceInvocationError || (serviceErrorLevel === Const.SYS_ERROR_LEVEL)) {
			return (
				messageModalWindow(this, this.state.header, this.state.text)
			)
		} 
		else if (serviceStatusCode === Const.NO_COOKIE_IN_HEADERS) {
			return <div>{Const.NO_COOKIE_IN_HEADERS_CHANGE_SETTINGS_MESSAGE}</div>;
		} 
		else if (serviceStatusCode === Const.NOT_CRITICALLY_BAD_COOKIE_WAS_SENT) {
			return <div>{Const.BAD_COOKIE_MESSAGE_CLOSE_BROWSER}</div>;
		} 
		else if (loginUser) {
			if (testDrive) {
				loginUser.testDrive = true;
				loginUser.firstTimeIn = true;
				return (
					<Redirect to={{ pathname: "/Profile", params: { loginUser } }} />
				)
			}
			else {
				loginUser.testDrive = false;
				return (
					isUserComplete 
					?  <Redirect to={{ pathname: "/Home", params: { loginUser } }} />
					:  <Redirect to={{ pathname: "/Profile", params: { loginUser } }} />
				)
			}
		} 
		else if ((serviceInvocationStatus !== Const.SERVICE_IS_NOT_INVOKED_YET)) {
			return sleepingCat();
		} 
		else {
			return (
				<div className="App-div">
					<div ref={ this.wrapperRef } style = {styleObj} className="after-app-wrap-center-login-div-walk" >
						<div className={`${loginBackgroundClass} row`}>

							<Col lg={6} md={12} sm={12} xs={12}>

								<div className="login-form">
									<div className="login-advertise pb-2">
									<Button className="btn-mea btn-lg mb-3" onClick={ (event) => handleTestDriveClick(event, this) }> 
											{Const.EXPLORE}
									</Button>
									</div>
									<Form onSubmit={this.handleSubmit}>
										<FormGroup >
											<div className="login-form-row-no-label">  
													<Input className="x-input"
														autoFocus={ focus }
														name="loginName" 
														placeholder="Username or email"
														value={this.state.loginName}
														type="text" required 
														onChange={this.handleChange}
														onFocus={() => onFocusHandler(this)}
															onBlur={() => onBlurHandler(this)}
														/>
												<div className = "error-message">
													<span>{this.state.loginNameError}</span>
												</div>
											</div>
											<div className="login-form-row-no-label">
												<div className="password">		   
													<Input 
														type={hide ? 'password' : 'text'} className="form-control x-input"
														name="password"
														placeholder="Password"
														maxLength = {20}
														value={this.state.password}
														onChange={this.handleChange}	
														onFocus={() => onFocusHandler(this)}
															onBlur={() => onBlurHandler(this)}															
													/> 															
													<div className="eye" onClick={this.hideSwitch}>
														{hide ? <RiIcons.RiEyeCloseLine /> : <BsIcons.BsEye />}
													</div> 																		
												</div>
												<div className = "error-message">
													<span>{this.state.passwordError}</span>
												</div>
											</div>							
											<Button className="btn-login btn-lg btn-block btn-dark mb-3" type="Submit"> Log in </Button>
											
											<div className="login-form-row-no-label" style={{height: 'auto'}}>
												<a className="pr-5" href={Envir.LOGIN_FOLDER + 'forgotpassword'}>Forgot password?</a>											
												<MDBBtn className="btn-imitation" onClick={this.toggle}>Sign up</MDBBtn>
											</div>
										</FormGroup>         
									</Form>
								</div>
							</Col>
							<Col lg={6} md={12} sm={0} xs={0} >
								<div className="Imaginewith2">
									<div className="imagine">Imagine <span className="script">With Medeata</span>
									</div>	
								</div>
							</Col>
						</div>
					</div>

					{ getWelcomeGreting(this) }
					{ getPolicy(this) }	
					
					<> 
						{contactUs()}
					</>
				</div>
			);
		}
	}
}