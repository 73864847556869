import { MDBBtn } from 'mdbreact';
import { Input, Col } from 'reactstrap';
import * as BsIcons from 'react-icons/bs';
import * as VscIcons from 'react-icons/vsc';
import Const from '../Const';
import GetYourPostBase from './GetYourPostBase';
import { toolTippy, changeHandlerNoEvent } from '../Utils.js';
import Consierge from '../../images/consierge-greet-avatar.png';
import Send from '../../images/send-blue.png';
import { getMarginLeftClassName, emojiCloseClickHandler } from '../../utils/PostUtils.js';

export default class GetYourPost extends GetYourPostBase {
	constructor(props) {
		super(props);
		this.state.aiInputClassName = "ai-input";
		this.state.isKeyboardUp = true; // this is a const value in this class
		this.autoFocus = true;
	}

	getIckon() {
		return (
			<div className="nav-link nav-icon create-post" onClick={this.toggle} >
				{ toolTippy(VscIcons.VscAdd, "Create Post", "bottom", [Const.DELAY, 0], "btn-desktop", null) }
			</div>
		)
	}

	getButton() {
		return (
		  (this.state.noPostText && this.state.imageIsNotUploaded) || this.textOverflow
		  ? <MDBBtn id="dialog-button" className="btn btn-primary pointer-noview"  disabled = { true } >Post</MDBBtn>
		  : <MDBBtn id="dialog-button" className="btn btn-primary" type="submit" value="Submit">Post</MDBBtn>
		)
	}

	getHeader() {
		return "Create Post";
	}

	getUploadImageIckon() {
        return (
          <div className="previewComponent">
              <label id="label" htmlFor="oneimage" className="pointer-view">
                <div className="media-icon">
				  { toolTippy(BsIcons.BsImageAlt, "Upload Image or Video", "bottom", [Const.DELAY, 0], "icon", null) }
                </div>
              </label>
              <input className="fileInput hidden" id="oneimage" disabled = {this.state.imgIckonDisabled}
                type="file" 
                accept={Const.ACCEPTED_IMAGE_TYPES}
                onChange={this.handleImageChange}
              />
		  </div>
        )
    }

	getEmoji() {
		return(
		  <div className="previewComponent pointer-view emojiImgDiv marginLeft" onClick={this.toggleEmojiEmojiPicker}>
			   <img src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f601.png" style={{ width: "24px", height: "24px" }} alt=""></img>
		  </div>
		)
	}
	  
	getToAIIckon() {
		let labelClassName = getMarginLeftClassName() + " pointer-view";
		let avatarClassName = getMarginLeftClassName() + " avatar-ai-sm";

        return (
          <div className="previewComponent">
              <label id="labelAI" htmlFor="oneimage" className={ labelClassName }>
				  	<div className={ avatarClassName } 
					     onClick={ () => this.higlightSelectedElement('button1')} 
						>
						{ toolTippy( Consierge, "Ask AI Concierge", "bottom", [Const.DELAY, 0], "", null) }
					</div>
              </label>
		  </div>
        )
    }

	// Emoji

	toggleEmojiEmojiPicker = () => {
		let showEmojiEmojiPicker = !this.state.showEmojiEmojiPicker;
		let body = document.getElementById('main');
	
		this.setState({
		  showEmojiEmojiPicker: showEmojiEmojiPicker
		});
		if (showEmojiEmojiPicker) {
		  body.onclick = this.handleEmojiCloseClick;
		}
		else {
		  body.onclick = null;
		}
	};

	handleEmojiClick(emojiObject) {
		let textArea = document.getElementById('textArea');
		let startPos = textArea.selectionStart;
		let endPos = textArea.selectionEnd;
		let postText= this.state.value;
		let newPosition = startPos + emojiObject.emoji.length;
	
		textArea.value = postText.substring(0, startPos) + emojiObject.emoji + postText.substring(endPos);
		textArea.selectionStart = newPosition;
		textArea.selectionEnd = newPosition;
		textArea.focus();
		changeHandlerNoEvent(textArea.value, this);
	}

	handleEmojiCloseClick(e) {
		emojiCloseClickHandler(e, this);
	}

	// AI concierge

	getNumberOfAIRequestsLeftDesktop(numberOfAIRequestsLeft, remainingRequestText) {
		let adjustedRemainingRequestText = this.adjustMarketPlaceCustomerText(numberOfAIRequestsLeft, remainingRequestText);
		
		return (
			 <Col lg={8} xs={10} className="center"> 
				<p id="remainingAIrequests" className="try">{ numberOfAIRequestsLeft + adjustedRemainingRequestText }</p> 	
			 </Col> 
		)
	}

	getProfileInfo(disabledAI) {
		return this.getProfileInfoDiv(disabledAI);
	}
	
	getRadioButtons(disabledAI) {
		return this.getRadioButtonsColumn(disabledAI);
	}

	getAIinput() {
		let loginUser = this.state.grandParent.state.loginUser;
		let aiLimitCond;

		if ((this.state.AIrequestOption === this.AI_IMAGE_CREATE) || (this.state.AIrequestOption === this.AI_POST_ILLUSTRATE) || (this.state.AIrequestOption === this.AI_POST_VIDEO_ILLUSTRATE)) {
			aiLimitCond = this.aiImageLimitCondition(loginUser);
		}
		else {
			aiLimitCond = this.aiPostLimitCondition(loginUser);
		}
		return (
				<Input id="aiInput" onChange={this.handleAIinputChange}
				    autoComplete="off"
					autoFocus = { this.autoFocus }
					name = "aiRequestTool" 
					maxLength = {this.AI_INPUT_MAX_LEN}
					placeholder = { this.state.AIrequestPlaceHolder }
					type = "text" required 
					value = { this.state.messageForAI }
					disabled = { 
								 !aiLimitCond ||
								 !this.state.submitToAINotSentYet 
							   }
					onFocus={ this.onFocusHandler }
					onBlur={ this.onBlurHandler} 
					className = "x-input ai-input-butmobile form-control">
			    </Input>
		)
	}

	getAIarrowButton(messageForAI, submitToAINotSentYet) {
		return (
				messageForAI && submitToAINotSentYet && this.state.isKeyboardUp 
				? <Col lg={1} md={1} xs={12} className="p-0 btn-look" onClick={ this.handleSubmitToAI }>
					<div className="send">
						<img src={ Send } className="pointer-view" alt="Avatar" />
					</div>
				  </Col>
				: <Col lg={1} md={1} xs={12} className="p-0 btn-look">
					<div className="send">
					  <img src={ Send } style = { this.LIGHT_FONT } alt="Avatar" />	
					</div>
				  </Col>
		)
	}
}