import { Row, Col } from 'reactstrap';
import { isDesktop } from './AppUtils.js';
import { remainingChars, invokeService, extractImageParts } from '../components/Utils.js';
import { uploadFile, deleteFile } from './FileUtils.js';
import Const from '../components/Const';
import EmojiPicker from 'emoji-picker-react';
import { invokeServer } from './SharePostUtils';

export function getEmojiPicker(that) {
  if (isDesktop()) {
    return ( 
      <EmojiPicker onEmojiClick={that.handleEmojiClick} />
    )
  }
  else {
    return ( 
      null
    )
  }
}

export function getBottomBar(charCounter, that) {
  if (isDesktop()) {
    return getBottomBarDeskTop(charCounter, that);
  }
  else {
    return getBottomBarMobilDevice(charCounter, that);
  }
}

export function getBottomBarDeskTop(charCounter, that) {
  return ( 
    <Row className="media1 row-margin-01" > 			 
      <Col className="p-0" md={ getUnitsNumberFirstColumn() } xs={6}>
        <Row>
          <Col>
            {
              that.getUploadImageIckon()
            }
          </Col>
          <Col className='marginLeft'>
            {
             that.getEmoji()
            }
          </Col>
          <Col className='marginLeft'>
            {
              that.getToAIIckon()
            }
          </Col>
        </Row>
      </Col>
      <Col className="p-0" md={ getUnitsNumberSecondColumn() } xs={6}>           
        {
          remainingChars(charCounter)
        }
      </Col>				
    </Row> 
  ) 
}

export function getBottomBarMobilDevice(charCounter, that) {
  // Any mobil device
  return ( 
    <Row className="media1 row-margin-01" > 			 
      <Col className="p-0" md={ getUnitsNumberFirstColumn() } xs={6}>
        {
          that.getUploadImageIckon()
        }

        {
          that.getToAIIckon()
        }
      </Col>
      <Col className="p-0" md={ getUnitsNumberSecondColumn() } xs={6}>           
        {
          remainingChars(charCounter)
        }
      </Col>				
    </Row> 
  ) 
}

export function getUnitsNumberFirstColumn() {
  let unitsNumber;

  if (isDesktop()) {
    unitsNumber = 3;
  }
  else {
    unitsNumber = 2;
  }
  return unitsNumber;
}

export function getUnitsNumberSecondColumn() {
  let unitsNumber;

  if (isDesktop()) {
    unitsNumber = 9;
  }
  else {
    unitsNumber = 10;
  }
  return unitsNumber;
}

export function getMarginLeftClassName() {
  let marginLeftClassName = '';

  if (isDesktop()) {
    marginLeftClassName = 'marginLeft';
  }
  else {
    marginLeftClassName = '';
  }
  return marginLeftClassName;
}

export function emojiCloseClickHandler(e, that) {
  let showEmojiEmojiPicker;
  let className;
  let cond1;
  let cond2;

  if (isDesktop()) {
    showEmojiEmojiPicker = that.state.showEmojiEmojiPicker;
    className = e.target.className;
    cond1 = (typeof className === 'string') ? (className === '') : true;
    cond2 = (typeof className === 'string') ? !(className.indexOf('epr') > -1) : true;
    if (showEmojiEmojiPicker && (cond1 || cond2)) {
      that.toggleEmojiEmojiPicker();
    }
  }
}

export function postsAndFollowersClassNames(containerName) {
  // consierge / consierge-desktop for browsing AI image is set not here.
  // consierge-desktop is set in ProfileOfLoginUser.getClassNameProfileBlock()
  // consiergeis set in ProfileOfLoginUser.getClassNameContainer().
  // "nav-display-desktop" is set in UniversalSearch.getNavOrSearchBoxOnTop()

  let isDesktopCond = isDesktop();
  let classNames = {
      postsDiv : isDesktopCond ? 'post-div-desktop' : 'post-div',
      postsDivProfile : isDesktopCond ? 'post-div-profile-desktop' : 'post-div-profile',
      rightDiv : isDesktopCond ? 'right-div-desktop' : 'right-div',
      rightDivProfile : isDesktopCond ? 'right-div-profile-desktop' : 'right-div-profile',
      scroll : isDesktopCond ? 'scroll-desktop' : 'scroll',
      afterAppWrapCenterDiv : isDesktopCond ? containerName + '-desktop' : containerName,
      bodyFullscreen3 : isDesktopCond ? 'body-fullscreen-3-desktop' : 'body-fullscreen-3',
      sugested : isDesktopCond ? 'suggested-desktop': 'suggested',
      username : isDesktopCond ? 'username-desktop': 'username',
      tagline : isDesktopCond ? 'tagline-desktop': 'tagline',
      iFollow : isDesktopCond ? 'ifollow-desktop': 'ifollow',
      loginBackground : isDesktopCond ? 'login-background-desktop': 'login-background'
    }

	return classNames;
}

export async function storeUserPostInDB(todayNumber, that) {
  let videoPreviewUrl = that.ImageUploadder.current.state.videoPreviewUrl; 
  let response;

  if (videoPreviewUrl) {
    that.currentVideoFile = { ...that.ImageUploadder.current.state.file };
    response = await storeVideoPostInDB(todayNumber, that);
  }
  else  {
    response = await storeRegularPostInDB(todayNumber, that);
  }
  return response.result;
}

export async function storeRegularPostInDB(todayNumber, that) {
  let imagePreviewUrl = that.ImageUploadder.current.state.imagePreviewUrl; 
  let imageParts;
  let imageType;
  let imageBody;
  let value = that.state.value;
  let data = {
      mode : Const.STORE_USER_POST_MODE,
      post : (value.length <= that.MAX_LENGTH) ? value : value.substring(0, that.MAX_LENGTH),
      number_date : todayNumber,
      
  };
  let response;

  if (imagePreviewUrl) {
      imageParts = extractImageParts(imagePreviewUrl);
      imageType = imageParts.image_type;
      imageBody = imageParts.image_body;
      //
      data.image_type = imageType;
      data.image_width = Math.ceil(that.ImageUploadder.current.imageParams.maxWidth);
      data.image_height = Math.ceil(that.ImageUploadder.current.imageParams.maxHeight);
      data.image = imageBody;
  }
  response = await invokeService(Const.OCA_SERVICES_URL, data, that);
  if (response.result) {
    response.result.data = data; // this is just to provide acess to this post image height and width 
  }
  return response;
}

export async function storeVideoPostInDB(todayNumber, that) {
  let value = that.state.value;
  let postData = {
        mode : Const.STORE_USER_POST_MODE,
        post : (value.length <= that.MAX_LENGTH) ? value : value.substring(0, that.MAX_LENGTH),
        number_date : todayNumber,
        video : true,
        image_width : that.ImageUploadder.current.state.file.width,
        image_height : that.ImageUploadder.current.state.file.height
      };
  let videoValidateData;
  let videoFile = that.ImageUploadder.current.state.file;
  let presignedUploadVideoUrl;
  let presignedDeleteVideoUrl;
  let realVideoUrl = Const.USER_VIDEO_URL + that.state.loginUser.userName + '/' + todayNumber;
  let response;

  for (let k = 0; k < 1; k++) { // one iteration loop
    response = await getVideoS3url(todayNumber, that);
    if (that.state.serviceStatusCode !== Const.OK) {
      break;
    }
    presignedUploadVideoUrl = response.result.upload_signed_url;
    presignedDeleteVideoUrl = response.result.delete_signed_url;
    response = await uploadFile(presignedUploadVideoUrl, videoFile, that);
    if (that.state.serviceStatusCode !== Const.OK) {
      break;
    }
    if (videoFile.type === 'video/mp4') {
      videoValidateData = {
                            videoType : videoFile.type,
                            videoUrl : realVideoUrl
                          };
      response = await invokeServer(Const.VPS_SERVER_URL_VALIDATE_CODEC, videoValidateData, that); 
      if (that.state.serviceStatusCode !== Const.OK) {
        if (presignedDeleteVideoUrl) {
          await deleteFile(presignedDeleteVideoUrl, that, false);
        }
        break;
      }
    }
    response = await invokeService(Const.OCA_SERVICES_URL, postData, that);
  }
  return response;
}

export async function getVideoS3url(todayNumber, that) {
  let videoFile = that.ImageUploadder.current.state.file;
  let data = {
      mode : Const.GET_PRESIGN_URL,
      number_date : todayNumber,
      file_type : videoFile.type // "video/mp4"
  };
  let response = await invokeService(Const.OCA_SERVICES_URL, data, that);
     
  return response;
}