import ReactPlayer from 'react-player';
import Const from '../components/Const';
import { setUnsupportedVideoCodecMessage, setUnsupportedVideoAudioMessage } from '../components/Utils';
import { isTabletLandscapeLayout } from './AppUtils';

export function renderVideoPlayer(url, styleObj, videoClass, muted, that, props = {}) {
  const onErrorHandle = (event) => {
    if (that && that.parent) {
      // for video preview only
      if (event.srcElement && event.srcElement.error && event.srcElement.error.message) {
        if (event.srcElement.error.message.toLowerCase().includes('audio')) {
          setUnsupportedVideoAudioMessage(that.parent);
        }
      }
      else {
        setUnsupportedVideoCodecMessage(that.parent);
      }
      that.parent.onErrorPopUpClose = onErrorPopUpClose; // to remove videpo preview on error pop-up close
    }
  };

  return (
    <div style = { styleObj } >
      <ReactPlayer className={`react-player ${videoClass}`} 
        playsinline
        url={url}
        controls
        muted={ muted }
        onError = { onErrorHandle }
        {...props}
      />
    </div>
  );
}

export function onErrorPopUpClose(that) {
  if (that && that.ImageUploadder.current) {
    that.ImageUploadder.current.setState({ imageIsNotUploaded : true, videoPreviewUrl : '', file : '' });
    delete that.onErrorPopUpClose;
  }
};

export function renderVideoPlayerWrapperM(post, playing, loop, muted = true) {
  let videoUrl = post.postVideo;
  let width;
  let height;
  let adjustment = 1;// making sure border is correctly displayed around video
  let videoPortretHeight = Const.POST_IMG_MAX_HEIGHT_PORTRAIT;
  let koeff;
  let myPlaying = playing;
  let myMuted = muted;
  let widthString;
  let heightString;
  let videoClass = 'post-video';
  let styleObj;
 
  if (post.imgHeight > post.imgWidth) {
    koeff = post.imgWidth / post.imgHeight;
    height = videoPortretHeight;
    width = koeff * height;
    if ((window.innerWidth - adjustment) <=  width) {
      width = window.innerWidth - adjustment;
      height = koeff * width;
    }
    widthString = width + 'px';
    heightString = height + 'px';
  }
  else {
    koeff = post.imgHeight / post.imgWidth;
    if (isTabletLandscapeLayout()) {
      width = Const.POST_IMG_MAX_WIDTH_LENDSCAPE;
      height = width * koeff;
      widthString = width + 'px';
      heightString = height + 'px';
    }
    else {
      width = 100;
      height = width * koeff;
      widthString = width + '%';
      heightString = height + '%';
    }
  }
  styleObj = { width:  widthString, height: heightString };
  if (playing && muted) {
    myPlaying = false;
    myMuted = false;
  }
  return renderVideoPlayer(videoUrl, styleObj, videoClass, myMuted, null, { 
                            playing: myPlaying, 
                            loop: loop,
                            width: widthString,
                            height: heightString
                          });
}

export function renderVideoPlayerWrapper(videoUrl, playing, loop, that, muted = true) {
  let { file } = that.state;
  let uploadImgOrVideo;
  let main;
  let rect;
  let iconsRectangleHeight;
  let bottomSafePadding ;
  let availableHeight; // this is the available height of the area below text area icons
  let availableWidth;
  let koeff;
  let height;
  let width;
  let widthString;
  let heightString;
  let styleObj = {};
  let videoClass = 'upload-video';
  
  if (that.styleVideoDimensions && (that.styleVideoDimensions.fileName === file.name)) {
    // This is because on some phones typing makes keyboard go up and confuses calculations
    widthString = that.styleVideoDimensions.widthString;
    heightString = that.styleVideoDimensions.heightString;
  }
  else {
    uploadImgOrVideo = document.getElementById('uploadImgOrVideoId');
    main = document.getElementById('textArea');
    rect = uploadImgOrVideo.getBoundingClientRect();
    iconsRectangleHeight = 50; // enough to cover below-post-text-area-icons height
    bottomSafePadding = 16;
    availableHeight = main.offsetHeight - rect.top - iconsRectangleHeight - bottomSafePadding;
    availableWidth = main.offsetWidth;
    height = availableHeight;
    width = file.width * (height / file.height); // proportionaly increased or decreased width
    if (width > availableWidth) {
      koeff = file.height / file.width;
      width = 100; // % we made width smaller
      height = width * koeff;
      widthString = width + '%';
      heightString = height + '%';
    }
    else {
      widthString = width + 'px';
      heightString = height + 'px';
    }
    that.styleVideoDimensions = {
                                  widthString : widthString,
                                  heightString : heightString,
                                  fileName : file.name
                                }
  }
  return renderVideoPlayer(videoUrl, styleObj, videoClass, muted, that, { 
                            playing: playing, 
                            loop: loop,
                            width: widthString,
                            height: heightString
                          });
}