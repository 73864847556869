import Const from '../components/Const';
import { setFileUploadFailureMessage, setFileUploadSystemDownMessage, 
         setFileDeleteFailureMessage, setFileDeleteSystemDownMessage } from '../components/Utils.js';

export async function uploadFile(presignedUrl, file, that) {
  let result;
  let response;
  let uploadStatus = null;
  let uploadError = null;

  that.setState({
    serviceInvocationStatus : Const.SERVICE_IS_BEIGNG_INVOKED
  });
  try {
    result = await fetch(presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });
    uploadStatus = uploadFileResultLogic(result, that);
  } 
  catch (err) {
    uploadError = err;
    uploadFileErrorLogic(err);
  }
  response = {
    result : uploadStatus,
    error : uploadError
  }
  return response;
}

export function uploadFileResultLogic(response, that) {
  let stateObj;
  let errorLevel;
  let serviceStatusCode;

  if (response.ok) {
    errorLevel = Const.NO_ERROR_LEVEL;
    serviceStatusCode = Const.OK;
  }
  else {
    errorLevel = Const.SYS_DOWN_LEVEL;
    serviceStatusCode = Const.SYS_ERROR_UPLOADING_FILE;
    setFileUploadFailureMessage(that);
  }
  stateObj = {
    serviceInvocationStatus :  Const.SERVICE_CALL_IS_COMPLETE,
    serviceStatusCode : serviceStatusCode,
    serviceErrorLevel : errorLevel
  }
  that.setState(stateObj);
  return serviceStatusCode;
}

export function uploadFileErrorLogic(err, that) {
  console.log(err);
  setFileUploadSystemDownMessage(that);
  that.setState({
                serviceStatusCode : Const.SYS_ERROR_UPLOAD_SERVICE_IS_DOWN,
                serviceInvocationError : true,
                serviceInvocationStatus :  Const.SERVICE_CALL_IS_COMPLETE
               });
}

export async function deleteFile(presignedUrl, that, isUpdateState) {
  let result;
  let response;
  let deleteStatus = null;
  let deleteError = null;

  if (isUpdateState) {
    that.setState({
      serviceInvocationStatus : Const.SERVICE_IS_BEIGNG_INVOKED
    });
  }
  try {
    result = await fetch(presignedUrl, {
      method: 'DELETE'
    });
    deleteStatus = deleteFileResultLogic(result, that, isUpdateState);
  } 
  catch (err) {
    deleteError = err;
    deleteFileErrorLogic(err, that, isUpdateState);
  }
  response = {
    result : deleteStatus,
    error : deleteError
  }
  return response;
}

export function deleteFileResultLogic(response, that, isUpdateState) {
  let stateObj;
  let errorLevel;
  let serviceStatusCode;

  if (response.ok) {
    errorLevel = Const.NO_ERROR_LEVEL;
    serviceStatusCode = Const.OK;
  }
  else {
    errorLevel = Const.SYS_DOWN_LEVEL;
    serviceStatusCode = Const.SYS_ERROR_DELETING_FILE;
    if (isUpdateState) {
      setFileDeleteFailureMessage(that);
    }
    
  }
  if (isUpdateState) {
    stateObj = {
      serviceInvocationStatus :  Const.SERVICE_CALL_IS_COMPLETE,
      serviceStatusCode : serviceStatusCode,
      serviceErrorLevel : errorLevel
    }
    that.setState(stateObj);
  }
  return serviceStatusCode;
}

export function deleteFileErrorLogic(err, that, isUpdateState) {
  console.log(err);
  if (isUpdateState) {
    setFileDeleteSystemDownMessage(that);
    that.setState({
                    serviceStatusCode : Const.SYS_ERROR_DELETE_SERVICE_IS_DOWN,
                    serviceInvocationError : true,
                    serviceInvocationStatus :  Const.SERVICE_CALL_IS_COMPLETE
                  });
    }
}